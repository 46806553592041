<template>
  <b-container>
    <company-select class="mb-3" @input="companySelected" />
    <ct-centered-spinner v-if="loading" />
    <div v-else>
      <div data-cy="or" class="row-or">
        <div>
          or
        </div>
      </div>
      <b-col class="text-center">
        <b-button data-cy="addBtn" to="/hire-us/add-company" variant="primary">
          Add a New Company to Your Account
        </b-button>
      </b-col>
    </div>
  </b-container>
</template>

<script>
  import CompanySelect from '@/components/CompanySelect'
  import router from '@/routes'
  import { mapActions } from 'vuex'
  import { tagSession } from '@/common/modules/inspectlet'

  export default {
    name: 'ChooseCompany',
    components: {
      CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner'),
      CompanySelect,
    },
    data() {
      return {
        loading: false,
      }
    },
    async mounted() {
      await this.resetState()
    },
    methods: {
      ...mapActions('checkout', ['resetState']),
      ...mapActions('companies', ['setCurrentCompany']),
      async companySelected(companyId) {
        this.loading = true
        tagSession({ hireUs: 'hireUs', companyId: companyId })

        try {
          await this.setCurrentCompany({ id: companyId, force: true })
          await router.push({ name: 'choose-category', params: { companyId } })
        } catch(_error) {
          // suppress error
        } finally {
          this.loading = false
        }
      },
    },
  }
</script>
<style lang="scss">
  .row-or {
    margin: 2rem auto;
    width: 35%;
    height: 1px;
    background-color: $ct-ui-font-color-secondary;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    div {
      color: $ct-ui-font-color-secondary;
      padding: 20px;
      background: white;
      text-align: center;
    }
  }
</style>
